import React from 'react';
import ReadMore from './ReadMore';

const DemoVideo = ({src, text}) => {
  return (
    <div>
      <div className='grey-text'><ReadMore text={text} /></div>
      <iframe 
        src={src}
        frameBorder="0" 
        webkitallowfullscreen 
        mozallowfullscreen 
        allowFullScreen
        style={{ width: '75%', height: '50vh' }}
      ></iframe>
    </div>
  );
}

export default DemoVideo;