
function Description() {
    return (

        <div id="description" className="fs-5 text-light justify-content-center text-center description">
            {/* <h1> */}
                <div>We develop <span className="ai-text-gradient">Human-Sounding AI Voice Agents</span> </div>
                <div>for your business use case</div>
            {/* </h1> */}
        </div>
    )

}

export default Description