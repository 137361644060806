import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';

function ModalPopup() {
  const [show, setShow] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const placeCall = (phoneNumber) => {
    fetch('https://voiceagentserver.online/placeClientCall', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone_number: phoneNumber }),
    })
    .then(response => response.json())
    .then(data => {
        console.log('Success:', data);
    })
    .catch((error) => {
        console.error('Error:', error);
    });
    }
  

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    console.log('Phone Number:', phoneNumber); // You can handle the phone number here
    placeCall(phoneNumber);
    handleClose(); // Close the modal after submission
  };

  return (
    <div className="App">
      <Button variant="" style={{ border: 'none' }} className='text-primary' onClick={handleShow}>
        Click here if you are from outside of the U.S.
      </Button>

      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Enter Phone Number</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Enter your phone number starting with your country code. After submitting you shall soon receive a call from our AI Voice Agent.</Form.Label>
              <Form.Control required type="text" placeholder="Eg. +917744038933" onChange={(e) => setPhoneNumber(e.target.value)}  />
            </Form.Group>
            <Button variant="primary" type="submit" className='mt-2'>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPopup;