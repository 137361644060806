const useCases = [
    {
      title: "Appointments",
      videoLink: "https://www.youtube.com/embed/uZEcVfWYyI4?si=Gj7jkF7rGkiIjy7d", // Replace with your actual video link
      description: "Simplify and accelerate the appointment scheduling process with our AI Voice agents. It talks with your customer and seamlessly manages booking requests, send reminders, and reschedule appointments, ensuring a smooth and efficient experience for your clients while freeing up your team's time. The video below demonstrates a sample appointment scheduling process for car servicing."
    },
    {
      title: "Interviews",
      videoLink: "https://www.youtube.com/embed/V8aZiawne0o?si=OGcSTiMJle4v6EdH", // Replace with your actual video link
      description: "Streamline your hiring process by allowing our AI Voice agents to conduct initial job interview calls. They effectively gather essential candidate's information, assess qualifications, and schedule further interview rounds, reducing your recruiters' workload and speeding up the recruitment process. The video below demonstrates a sample introduction interview call for a HVAC company."
    },
    {
      title: "Feedbacks",
      // videoLink: "https://www.loom.com/embed/7b2dd40df5604733807f1f13b9719f5c?sid=47767c5d-e13b-4807-ac0d-647aa080d175", // Replace with your actual video link
      videoLink: "https://www.youtube.com/embed/6zWG1MaaJZk?si=NerBVOhFuAdBGfO4",
      description: "Enhance your customer satisfaction by using our AI Voice agents to gather timely and insightful feedback from recent customers. They engage with your customers, ask relevant questions, dive deeper into the problems, and compile actionable insights, helping you improve your products or services, build stronger customer relationships and ultimately boost your Net Promoter Score."
    },
    {
      title: "Customer-Service",
      videoLink: "https://www.youtube.com/embed/xVOfKgUwN-g?si=oDYOqyTaBP8bOI9x", // Replace with your actual video link
      description: "Revolutionize your customer support with our AI Voice agents that can handle common inquiries, troubleshoot issues, and escalate complex cases to human agents. This reduces call volumes, enhances response times, and ensures a consistently high level of customer service."
    }
  ];
  
  export default useCases;