import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/esm/Container';
import DemoVideo from './DemoVideo';
import useCases from './UseCases';
import ReadMore from "./ReadMore";

function UseCaseBar({ socket }) {

    const location = useLocation();

    // Function to get the tab from URL
    const getTabFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get('tab') || 'Appointments';
    };

    const [activeTab, setActiveTab] = useState(getTabFromUrl());

    const handleSelect = (selectedTab) => {
      setActiveTab(selectedTab);
      console.log("User selected tab:", selectedTab); // Logs the currently selected tab
  };

  useEffect(() => {
    socket.emit("active_tab", activeTab);
  }, [socket, activeTab]);


  return (
    <Container className='justify-content-center text-center text-light align-items-center border border-secondary rounded p-3'>
      <div>
        <h3>Unlock the power of AI Voice Agents for your business</h3>
        <p><ReadMore text={"Use our advanced human-sounding AI voice agents to automate customer interactions for your business. Our AI is built using the most advanced technology ensuring that it provides a smooth and comfortable experience for your customers. By using our technology, you can focus on what matters most to your customers, and our AI voice agents will do the rest. By automating customer interactions, you can save costs and time, at the same time ensuring that your customers get - fast, efficient and human-like service, thereby ultimately increasing your revenue and Net Promoter Score. Our AI is completely customizable for your business use-case and we will work to integrate with your existing software and get it running for you. Just tell us about your use-case and we will do the rest. Below are a few common use-cases along with their demo video."} /> 
        </p>
      </div>
        <Tabs
        defaultActiveKey={getTabFromUrl()}
        onSelect={handleSelect}
        id="justify-tab-example"
        // className="mb-3 custom-tabs"
        className="mb-3"
        justify
        >
        {useCases.map((useCase) => (
            <Tab eventKey={useCase.title} title={useCase.title}>
                <DemoVideo src={useCase.videoLink} text={useCase.description} />
            </Tab>
        ))}
        </Tabs>
    </Container>
    
  );
}

export default UseCaseBar;