const solutions = [
    {
      solution: "Hotels",
      title: "AI Voice Agents for Hotels",
      subheading: "Transform your hotel’s customer service with our AI Voice Agents, designed to engage guests in human-like conversations. Enhance customer satisfaction with reduced wait times, faster response and streamline interactions, delivering a superior, personalized experience that keeps guests coming back. Our AI Voice Agents are completely customizable and can be designed to interact however you want it for your custom use case. Below are a few use-cases, but we'd love to hear if you have any other use-case ideas as well.", 
      usecases: [
        {
          title: "AI Front Desk Agent",
          subheading: "The AI talks with your customers very much like a human and navigates them through the entire hotel booking process in a fast and efficient way. This not only reduces your burden to manage all these routine calls but also enhances customer satisfaction with no wait times and quicker responses.",
          videoLink: "https://www.youtube.com/embed/shUhrNq-1bc?si=fvCeRz7kxRllJauL",
        },
        {
          title: "AI Feedback Agent",
          subheading: "The AI calls your recent visitors and takes feedback about their stay at your hotel. If something went wrong it can ask deeper questions, ultimately leaving the customers heard and providing you with the overview and insights of what can be improved and what your customers have to say about you. This is a quite new and advanced technology as compared to the rule based traditional IVRs.",
          videoLink: "https://www.youtube.com/embed/6zWG1MaaJZk?si=DkZk5QLP6uFoi89w",
        },
        {
          title: "AI Booking Agent",
          subheading: "The AI talks with your customers very much like a human and navigates them through the entire hotel booking process in a fast and efficient way. This not only reduces your burden to manage all these routine calls but also enhances customer satisfaction with no wait times and quicker responses.",
          videoLink: "https://www.youtube.com/embed/WEn8XC2sNFM?si=nMOId0Y-gXBjYOCz",
        }
      ]
    },
    
  ];
  
  export default solutions;