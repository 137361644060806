function WavyLines() {
    return (
        <div className="wavy-lines justify-content-center text-center">
            <svg
                width="50%"
                height="20%"
                viewBox="0 0 364 201"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
            >
                <path
                    d="M5.88928 120C60 10 150 10 200 120C250 230 350 230 362.889 120"
                    stroke="url(#paint0_linear_25:218)"
                />
                <path
                    d="M-22.1107 120C20 10 110 10 160 120C210 230 310 230 334.889 120"
                    stroke="url(#paint1_linear_25:218)"
                />
                <path
                    d="M-53.1107 120C-10 10 80 10 130 120C180 230 280 230 303.889 120"
                    stroke="url(#paint2_linear_25:218)"
                />
                <path
                    d="M-98.1618 115C-60 5 30 5 80 115C130 225 230 225 281.856 115"
                    stroke="url(#paint3_linear_25:218)"
                />
                <path
                    d="M-140.1618 120C-100 10 0 10 50 120C100 230 200 230 250 120"
                    stroke="url(#paint4_linear_25:218)"
                />
                <path
                    d="M-180.1618 120C-140 10 -50 10 0 120C50 230 150 230 200 120"
                    stroke="url(#paint5_linear_25:218)"
                />
                <path
                    d="M-220.1618 120C-180 10 -90 10 -40 120C10 230 110 230 160 120"
                    stroke="url(#paint6_linear_25:218)"
                />
                <path
                    d="M-260.1618 120C-220 10 -130 10 -80 120C-30 230 70 230 120 120"
                    stroke="url(#paint7_linear_25:218)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_25:218"
                        x1="184.389"
                        y1="69.2405"
                        x2="184.389"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_25:218"
                        x1="156.389"
                        y1="69.2405"
                        x2="156.389"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_25:218"
                        x1="125.389"
                        y1="69.2405"
                        x2="125.389"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_25:218"
                        x1="93.8507"
                        y1="67.2674"
                        x2="89.9278"
                        y2="210.214"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_25:218"
                        x1="62.389"
                        y1="69.2405"
                        x2="62.389"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_25:218"
                        x1="32.389"
                        y1="69.2405"
                        x2="32.389"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_25:218"
                        x1="-0.61005"
                        y1="69.2405"
                        x2="-0.61005"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_25:218"
                        x1="-31.6101"
                        y1="69.2405"
                        x2="-31.6101"
                        y2="212.24"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#e3d76b46" stopOpacity="0" />
                        <stop offset="1" stopColor="#e3d76b46" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default WavyLines;