import React, { useState } from 'react';

const ReadMore = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Split text into words
  const words = text.split(' ');

  // Show first 25 words if not expanded, else show full text
  const displayedText = isExpanded ? text : words.slice(0, 30).join(' ');

  return (
    <div>
      <p>
        {displayedText}
        {words.length > 30 && !isExpanded && (
          <span>
            ...{' '}
            <span
              style={{ cursor: 'pointer' }}
              className='text-primary'
              onClick={() => setIsExpanded(true)}
            >
              Read more
            </span>
          </span>
        )}
        {isExpanded && (
          <span
            style={{cursor: 'pointer' }}
            className='text-primary'
            onClick={() => setIsExpanded(false)}
          >
            {' '}
            Read less
          </span>
        )}
      </p>
    </div>
  );
};

export default ReadMore;