
function BazzukaDemoVideo() {
    return (
        <div className="d-flex justify-content-center text-center text-light align-items-center mt-3 mt-lg-5">
              <iframe 
                src={'https://www.youtube.com/embed/Ainu3H5ZPIw?si=frZehBHvhLbbaj7b'}
                frameBorder="0" 
                webkitallowfullscreen 
                mozallowfullscreen 
                allowFullScreen
                style={{ width: '75%', height: '50vh' }}
            ></iframe>
        </div>      
    );
}    
export default BazzukaDemoVideo