import React, { createContext, useState } from 'react';

// Create the context
export const IncomingEventContext = createContext();

// Create a provider component
export const IncomingEventProvider = ({ children }) => {
  const [demoPressed, setDemoPressed] = useState(false);

  return (
    <IncomingEventContext.Provider value={{ demoPressed, setDemoPressed }}>
      {children}
    </IncomingEventContext.Provider>
  );
};