import React from "react";
import "./style.css";
import MicButton from "./MicButton";
import Captions from "./Captions";
import { io } from "socket.io-client";
import TextToSpeech from "./TextToSpeech";
import { IncomingEventProvider } from "./IncomingEventContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./Navbar";
import Footer from "./Footer";
import Description from "./Description";
import WavyLines from "./WavyLines";
import UseCaseBar from "./UseCaseBar";
import TextToSpeech2 from "./TextToSpeech2";
import DemoHeading from "./DemoHeading";
import { Route, Routes } from 'react-router-dom';
import SolutionsPage from "./SolutionsPage";
import PhoneDemo from "./PhoneDemo";
import BazzukaDemoVideo from "./BazzukaDemoVideo";



// const socket = io("http://localhost:8080");
const socket = io("https://voiceagentserver.online/browserserver");

function App() {
  return (

      <>
      <Navbar />
      <Routes>
        
        {/* <Route path="/" element={
          <div className="app">
            <WavyLines/>
            <Description/>
            <div className="use-case-bar">
              <UseCaseBar socket={socket}/>
            </div>
            <DemoHeading/>
            <IncomingEventProvider>
              <div className="content">
                <MicButton socket={socket} />
              </div> 
              <TextToSpeech2 socket={socket} />
            </IncomingEventProvider>
            
          </div>
        } /> */}

        <Route path="/" element={
          <div className="app p-1">
            <WavyLines/>
            <Description/>
            <div className="use-case-bar">
              <UseCaseBar socket={socket}/>
            </div>
            <PhoneDemo/>
            <BazzukaDemoVideo/>
          </div>
        } />
        
       
        <Route path="/solutions" element={<SolutionsPage />} />
      </Routes>
      <Footer/>
    </>
   
  );
}

export default App;




