import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/esm/Container";
import { useLocation } from 'react-router-dom';
import DemoVideo from "./DemoVideo";
import solutions from "./Solutions";
function SolutionsPage() {
  const location = useLocation();

  const getTabFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('solution');
  };

  const [solutionType, setSolutionType] = useState(getTabFromUrl());

  return (
    <Container className='justify-content-center text-center text-light align-items-center mt-3'>
      {/* <h1>Solutions Page</h1> */}
      {
        solutions.map((solution) => (
          solution.solution === solutionType && (
            <div className="">
                <h1 className="text-light ai-text-gradient">{solution.title}</h1>
                <p className="grey-text fs-5">{solution.subheading}</p>
                <div>
                    {
                    solution.usecases.map((usecase) => (
                        <div className="mt-5">
                            <h2>{usecase.title}</h2>
                            <DemoVideo src={usecase.videoLink} text={usecase.subheading} />
                        </div>
                    ))
                    }
                </div>
            </div>
            )
        ))
        
      }
      {!solutionType && (
        <p>Please select a solution from the dropdown menu.</p>
      )}
    </Container>
  );
}

export default SolutionsPage;