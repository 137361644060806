function TopStrip() {
    return (
        <div className="top-strip">
            <div className="d-flex justify-content-center align-items-center ai-gradient-solid">
                <p className="text-light m-1">Our AI Voice Agent <i>'Ella'</i> is here to help you! Call her on <a href="tel:+18333172760" className="text-light"><b>+18333172760</b></a> to learn more about us.</p>
            </div>
        </div>
    );
}

export default TopStrip