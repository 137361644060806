import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/esm/Container";
import aiimage from './sparkling.png'
import ModalPopup from "./ModalPopup";

function PhoneDemo() {
    return (
    <div>
        <Container className="justify-content-center text-center text-light align-items-center mt-3 mt-lg-5 border border-secondary rounded p-3 ai-gradient">
            <div className="d-flex justify-content-center text-center text-light align-items-center">
                <img src={aiimage} alt="aiimage" className="m-2" style={{ width: '35px', height: '35px' }} />
                <h3 className="fw-bold">Try it out yourself!</h3>   
            </div>
            <div className="mt-2">
                <p>
                    Call on <a href="tel:+18333172760"><b>+18333172760</b></a> to try it out! 
                <br/>
                    <ModalPopup />
                </p>
                <p>Let our AI Voice Agent <i>'Ella'</i> explain to you in detail about our products and how it can help your business!</p>
            </div>
                      
        </Container>
    </div>
    );
}

export default PhoneDemo