import React from 'react';

const Footer = () => {
    return (
        <footer className="footer text-white py-4 ai-gradient-left">
            <div className="container h-100 d-flex justify-content-center align-items-center">
                <div className="col-md-6">
                    <h5 className="text-center dark-text">Get in Touch</h5>
                    <form action="https://formspree.io/f/xldrnawd" method="POST">
                        <div className="form-group m-2">
                            <input required type="text" name="text" className="form-control text-white" id="name" placeholder="Enter your name" />
                        </div>
                        <div className="form-group m-2">
                            {/* <label htmlFor="email">Email address</label> */}
                            <input required type="email" name='email' className="form-control  text-white" id="email" placeholder="Enter your email" />
                        </div>
                        <div className="form-group m-2">
                            {/* <label htmlFor="message">Message</label> */}
                            <textarea required className="form-control text-white" name="message" id="message" rows="3" placeholder="Your message"></textarea>
                        </div>
                        <button type="submit" id="submit-btn" className="btn w-100 m-2">Submit</button>
                    </form>
                </div>
            </div>
        </footer>
    );
};

export default Footer;